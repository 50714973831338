import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
    CHANGE_PASSWORD,
    GOOGLE_LOGIN_PATH,
    LOGIN_USER_PATH,
    REGISTER_USER_PATH,
    RESET_PASSWORD,
} from '../constants/paths'
import { USER_INFO } from '../constants/storageKeys'
import { setHeader } from '../services/api'
import { removeStorage, setStorage } from '../services/storage'
import settings from '../settings'
import {
    changePasswordFailure,
    changePasswordRequest,
    changePasswordSuccess,
    googleLoginFailure,
    googleLoginRequest,
    googleLoginSuccess,
    loginFailure,
    loginRequest,
    loginSuccess,
    registerUserFailure,
    registerUserRequest,
    registerUserSuccess,
    resetPasswordFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
} from '../slices/authentication'

export function* registerUser({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.AUTH_URL + REGISTER_USER_PATH, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(registerUserSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(registerUserFailure(message))
    }
}

export function* googleLogin({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.AUTH_URL + GOOGLE_LOGIN_PATH, payload, {
                headers: setHeader(),
            })
        })

        if (response && response.status === 201) {
            if (response.data.notRegister) {
                setStorage({
                    key: 'register',
                    val: response.data,
                })
            } else if (response.data._id) {
                setStorage({
                    key: USER_INFO,
                    val: response.data,
                })
                removeStorage('register')
            }
            yield put(googleLoginSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(googleLoginFailure(message))
    }
}

export function* loginUser({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.AUTH_URL + LOGIN_USER_PATH, payload, {
                headers: setHeader(),
            })
        })

        if (response && response.status === 201) {
            setStorage({
                key: USER_INFO,
                val: response.data,
            })

            // yield delay(800)
            yield put(loginSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(loginFailure(message))
    }
}

export function* changePassword({ payload }) {
    try {
        const response = yield call(() => {
            return axios.put(
                settings.AUTH_URL + CHANGE_PASSWORD + '/' + payload.account,
                payload,
                {
                    headers: setHeader(),
                }
            )
        })

        if (response && response.status === 200) {
            setStorage({
                key: USER_INFO,
                val: response.data,
            })

            // yield delay(800)
            yield put(changePasswordSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(changePasswordFailure(message))
    }
}

export function* resetPassword({ payload }) {
    try {
        const response = yield call(() => {
            return axios.put(
                settings.AUTH_URL + RESET_PASSWORD + '/' + payload.account,
                payload,
                {
                    headers: setHeader(),
                }
            )
        })

        if (response && response.status === 200) {
            setStorage({
                key: USER_INFO,
                val: response.data,
            })

            // yield delay(800)
            yield put(resetPasswordSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(resetPasswordFailure(message))
    }
}

export default function* authenticationSaga() {
    yield takeLatest(registerUserRequest().type, registerUser)
    yield takeLatest(loginRequest().type, loginUser)
    yield takeLatest(googleLoginRequest().type, googleLogin)
    yield takeLatest(changePasswordRequest().type, changePassword)
    yield takeLatest(resetPasswordRequest().type, resetPassword)
}
