export const METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch',
}

export const LOADING_STATUS = {
    IDE: 'idle',
    LOADING: 'loading',
    success: 'success',
    FAILED: 'failed',
}
