import { call, delay, put, takeEvery } from 'redux-saga/effects'
import { FIND_PROGRESSIONS_PATH } from '../constants/paths'
import { setHeader } from '../services/api'
import settings from '../settings'
import {
    getProgressionFailure,
    getProgressionRequest,
    getProgressionSuccess,
} from '../slices/progression'
import axios from '../utils/axios'
export function* getProgression({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(
                settings.API_URL + FIND_PROGRESSIONS_PATH,
                payload,
                {
                    headers: setHeader(),
                }
            )
        })
        if (response && response.status === 201) {
            yield delay(800)
            yield put(getProgressionSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(getProgressionFailure(message))
    }
}

export default function* progressionSaga() {
    yield takeEvery(getProgressionRequest().type, getProgression)
}
