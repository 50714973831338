import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { USER_PATH } from '../constants/paths'
import { USER_INFO } from '../constants/storageKeys'
import { setHeader } from '../services/api'
import { getStorage } from '../services/storage'
import settings from '../settings'
import {
    resetUserState,
    updateUserFailure,
    updateUserRequest,
    updateUserSuccess,
    userDetailFailure,
    userDetailRequest,
    userDetailSuccess,
} from '../slices/user'
import axios from '../utils/axios'

const user = getStorage(USER_INFO)
export function* userDetail({ payload }) {
    try {
        const response = yield call(() => {
            return axios.get(settings.API_URL + USER_PATH + payload, payload, {
                headers: setHeader(),
            })
        })
        //   const user = yield response.json();
        //   yield put(userDetailSuccess(user));
        if (response && response.status === 200) {
            yield delay(800)
            yield put(userDetailSuccess(response))
        }
    } catch (error) {
        console.log(error)
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(userDetailFailure(message))
    }
}

export function* updateUser({ payload }) {
    try {
        const response = yield call(() => {
            return axios.put(settings.API_URL + USER_PATH + user._id, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 200) {
            yield delay(800)
            yield put(updateUserSuccess(response))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(updateUserFailure(message))
    }
}
function* resetUser() {
    try {
        yield console.log('reset')
    } catch (error) {
        console.log(error)
    }
}
export default function* userSaga() {
    yield takeLatest(userDetailRequest().type, userDetail)
    yield takeLatest(updateUserRequest().type, updateUser)
    yield takeLatest(resetUserState().type, resetUser)
}
