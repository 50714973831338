import { call, put, takeEvery } from 'redux-saga/effects'
import {
    GET_ATTACHMENT,
    UPLOAD_ATTACHMENT,
    UPLOAD_IMAGE,
} from '../constants/paths'

import { setHeader } from '../services/api'

import settings from '../settings'
import {
    getAttachmentContentFailure,
    getAttachmentContentRequest,
    getAttachmentContentSuccess,
    uploadAttachmentFailure,
    uploadAttachmentRequest,
    uploadAttachmentSuccess,
    uploadImageFailure,
    uploadImageRequest,
    uploadImageSuccess,
} from '../slices/file'
import axios from '../utils/axios'

export function* getAttachment({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.FILE_URL + GET_ATTACHMENT, payload, {
                headers: setHeader(),
            })
        })

        if (response && response.status === 201) {
            // yield delay(800)
            yield put(getAttachmentContentSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(getAttachmentContentFailure(message))
    }
}
export function* uploadAttachment({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.FILE_URL + UPLOAD_ATTACHMENT, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(uploadAttachmentSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(uploadAttachmentFailure(message))
    }
}

export function* uploadImage({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.FILE_URL + UPLOAD_IMAGE, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(uploadImageSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(uploadImageFailure(message))
    }
}

export default function* fileSaga() {
    yield takeEvery(getAttachmentContentRequest().type, getAttachment)
    yield takeEvery(uploadAttachmentRequest().type, uploadAttachment)
    yield takeEvery(uploadImageRequest().type, uploadImage)
}
