// ==================== AUTH PATH =====================
export const REGISTER_USER_PATH = '/auth/signup/'
export const LOGIN_USER_PATH = '/auth/login/'
export const GOOGLE_LOGIN_PATH = '/auth/google'
export const CHANGE_PASSWORD = '/edu/users/change-password'
export const RESET_PASSWORD = '/edu/users/reset-password'

// ==================== USER PATH =====================
export const USER_PATH = '/edu/users/'

// ==================== EDUCATION PATH =====================
export const EDUCATIONS_PATH = '/edu/educations/'
export const FIND_EDUCATIONS_PATH = '/edu/educations/find'

// ==================== MAJOR PATH =====================
export const MAJORS_PATH = '/edu/majors'
export const FIND_MAJORS_PATH = '/edu/majors/find'

// ==================== DOCUMENT PATH =====================
export const FIND_DOCUMENTS_PATH = '/edu/documents/find'
export const DOCUMENTS_PATH = '/edu/documents/'

// ==================== SUBJECT PATH =====================
export const FIND_SUBJECTS_PATH = '/edu/subjects/find'
export const SUBJECTS_PATH = '/edu/subjects/'

// ==================== EXAM PATH =====================
export const FIND_EXAMS_PATH = '/exam/tests/find'
export const EXAMS_PATH = '/exam/tests'
export const EXAMS_DETAIL_PATH = '/exam/tests/detail/'
export const SUBMIT_EXAMS_PATH = '/exam/tests/submit/'

// ==================== QUESTION PATH =====================
export const FIND_QUESTIONS_PATH = '/exam/questions/find'
export const QUESTIONS_PATH = '/exam/questions'
export const IMPORT_QUESTIONS_PATH = '/exam/questions/import'
export const QUESTIONS_DETAIL_PATH = '/exam/questions/detail/'

// ==================== FILE PATH =====================
export const GET_ATTACHMENT = '/upload/download/'
export const UPLOAD_ATTACHMENT = '/upload/attachments/'
export const UPLOAD_IMAGE = '/upload/image'
export const UPLOAD_CERTIFICATES = '/upload/certificates'
export const UPLOAD_LARGE_IMAGE = '/upload/large-image'

// ==================== CATEGORY PATH =====================
export const CATEGORIES_PATH = '/categories/'
export const FIND_CATEGORIES_PATH = '/categories/find'

// ==================== Course PATH =====================
export const COURSES_PATH = '/edu/courses/'
export const FIND_COURSES_PATH = '/edu/courses/find'
export const COURSES_QUIZ_PATH = '/exam/questions/find'
export const PROGRESSIONS_PATH = '/edu/progressions/'
export const FIND_PROGRESSIONS_PATH = '/edu/progressions/find'
export const JOIN_COURSE_PATH = '/edu/enrolls'
export const ASSESSMENT_PATH = '/assessment/'
export const FIND_ASSESSMENT_PATH = '/assessment/find'

// ==================== NOTES PATH =====================
export const FIND_NOTE_PATH = '/edu/notes/find'
export const NOTE_PATH = '/edu/notes'

// ==================== LESSONS PATH =====================
export const FIND_LESSONS_PATH = '/edu/lessons/find'
export const LESSONS_PATH = '/edu/lessons'

// ==================== TOPIC PATH =====================
export const FIND_TOPIC_PATH = '/edu/topics/find'
export const TOPIC_PATH = '/edu/topics'

// ==================== COMMENT PATH =====================
export const COMMENT_PATH = '/comments/'
export const FIND_COMMENTS_PATH = '/comments/find'
export const COMMENTS_DETAIL_PATH = '/comments/'

// ==================== BANNER PATH =====================
export const BANNERS_MENTOR_PATH = '/edu/mentorIntroduce/'
export const FIND_BANNERS_MENTOR_PATH = '/edu/mentorIntroduce/find'

// ==================== ACTIVATION CODE PATH =====================
export const FIND_ACTIVATION_CODE_PATH = '/edu/activations/find'
export const ACTIVATION_CODE_PATH = '/edu/activations'

// ==================== Enroll PATH =====================
export const ENROLL_PATH = '/edu/enrolls/find'

// ==================== ANNOUNCEMENT PATH =====================
export const ANNOUNCEMENT_PATH = '/announcements/find'

// ==================== PAYMENT PATH =====================
export const PAYMENT_CHECKOUT_PATH = '/payment/checkout'
export const PAYMENT_CHECKOUT_POINT = '/payment/checkpoint'
export const PAYMENT_CALLBACK_PATH = '/vnpay/callback'

export const FIND_TRANSACTION_PATH = '/transactions/find'
export const TRANSACTION_PATH = '/transactions'

// ==================== NOTIFICATIONS PATH =====================
export const NOTIFICATIONS = '/notifications'
export const NOTIFICATIONS_PATH = '/notifications/find'

// ==================== PEDAGOGY PATH =====================
export const FIND_PEDAGOGY_PATH = '/pedagogy/find'
export const PEDAGOGY_PATH = '/pedagogy'
export const FIND_ANSWER_PEDAGOGY_PATH = '/answer/find'
export const ANSWER_PEDAGOGY_PATH = '/answer'

// ==================== CONFIGS PATH =====================
export const CONFIGS_PATH = '/configs'
