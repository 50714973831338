import { call, put, takeEvery } from 'redux-saga/effects'
import { FIND_LESSONS_PATH, LESSONS_PATH } from '../constants/paths'

import { setHeader } from '../services/api'

import settings from '../settings'
import {
    createLessonsFailure,
    createLessonsRequest,
    createLessonsSuccess,
    deleteLessonsFailure,
    deleteLessonsRequest,
    deleteLessonsSuccess,
    getLessonDetailFailure,
    getLessonDetailRequest,
    getLessonDetailSuccess,
    getLessonsFailure,
    getLessonsRequest,
    getLessonsSuccess,
    resetLessonsState,
    updateLessonsFailure,
    updateLessonsRequest,
    updateLessonsSuccess,
} from '../slices/lessons'
import axios from '../utils/axios'

export function* getLessons({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.API_URL + FIND_LESSONS_PATH, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(getLessonsSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(getLessonsFailure(message))
    }
}

export function* getLessonDetail({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.API_URL + LESSONS_PATH + '/' + payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(getLessonDetailSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(getLessonDetailFailure(message))
    }
}

export function* createLessons({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.API_URL + LESSONS_PATH, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(createLessonsSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(createLessonsFailure(message))
    }
}

export function* updateLessons({ payload }) {
    try {
        const response = yield call(() => {
            return axios.put(
                settings.API_URL + LESSONS_PATH + '/' + payload.id,
                payload.body,
                {
                    headers: setHeader(),
                }
            )
        })
        if (response && response.status === 200) {
            // yield delay(800)
            yield put(updateLessonsSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(updateLessonsFailure(message))
    }
}

export function* deleteLessons({ payload }) {
    try {
        const response = yield call(() => {
            return axios.delete(
                settings.API_URL + LESSONS_PATH + '/' + payload,
                payload,
                {
                    headers: setHeader(),
                }
            )
        })
        if (response && response.status === 200) {
            // yield delay(800)
            yield put(deleteLessonsSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(deleteLessonsFailure(message))
    }
}

function* resetLessons() {
    try {
        yield console.log('reset')
    } catch (error) {
        console.log(error)
    }
}

export default function* lessonSaga() {
    yield takeEvery(getLessonsRequest().type, getLessons)
    yield takeEvery(getLessonDetailRequest().type, getLessonDetail)
    yield takeEvery(createLessonsRequest().type, createLessons)
    yield takeEvery(updateLessonsRequest().type, updateLessons)
    yield takeEvery(deleteLessonsRequest().type, deleteLessons)
    yield takeEvery(resetLessonsState().type, resetLessons)
}
