import { call, put, takeEvery } from 'redux-saga/effects'
import { FIND_SUBJECTS_PATH, SUBJECTS_PATH } from '../constants/paths'

import { setHeader } from '../services/api'

import settings from '../settings'
import {
    findSubjectFailure,
    findSubjectRequest,
    findSubjectSuccess,
    subjectDetailFailure,
    subjectDetailRequest,
    subjectDetailSuccess,
} from '../slices/subjects'
import axios from '../utils/axios'

export function* findSubjects({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.API_URL + FIND_SUBJECTS_PATH, payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 201) {
            // yield delay(800)
            yield put(findSubjectSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(findSubjectFailure(message))
    }
}
export function* findSubjectsDetail({ payload }) {
    try {
        const response = yield call(() => {
            return axios.get(settings.API_URL + SUBJECTS_PATH + payload, {
                headers: setHeader(),
            })
        })
        if (response && response.status === 200) {
            yield put(subjectDetailSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'

        yield put(subjectDetailFailure(message))
    }
}

export default function* enrollSaga() {
    yield takeEvery(findSubjectRequest().type, findSubjects)
    yield takeEvery(subjectDetailRequest().type, findSubjectsDetail)
}
