import { call, put, takeEvery } from 'redux-saga/effects'
import { ANNOUNCEMENT_PATH } from '../constants/paths'

import { setHeader } from '../services/api'

import settings from '../settings'
import {
    getAnonouncementFailure,
    getAnonouncementRequest,
    getAnonouncementSuccess,
} from '../slices/announcement'
import axios from '../utils/axios'

export function* getAnonouncement({ payload }) {
    try {
        const response = yield call(() => {
            return axios.post(settings.API_URL + ANNOUNCEMENT_PATH, payload, {
                headers: setHeader(),
            })
        })

        if (response && response.status === 201) {
            // yield delay(800)
            yield put(getAnonouncementSuccess(response.data.docs))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(getAnonouncementFailure(message))
    }
}

export default function* announcementSaga() {
    yield takeEvery(getAnonouncementRequest().type, getAnonouncement)
}
