import { call, put, takeEvery } from 'redux-saga/effects'
import { CONFIGS_PATH } from '../constants/paths'
import { setHeader } from '../services/api'
import settings from '../settings'
import {
    getconfigsFailure,
    getconfigsRequest,
    getconfigsSuccess,
} from '../slices/configs'
import axios from '../utils/axios'

export function* getConfigs({ payload }) {
    try {
        const response = yield call(() => {
            return axios.get(settings.API_URL + CONFIGS_PATH, payload, {
                headers: setHeader(),
            })
        })

        if (response && response.status === 200) {
            yield put(getconfigsSuccess(response.data))
        }
    } catch (error) {
        const message =
            error.response.data.message ||
            error.response.data.errorMessage ||
            'Something went wrong!'
        yield put(getconfigsFailure(message))
    }
}

export default function* categorySaga() {
    yield takeEvery(getconfigsRequest().type, getConfigs)
}
